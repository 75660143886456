import images from "@assets/images";
import { StackActions, useNavigation, useRoute } from "@react-navigation/native";
import { DimensionContext } from "@src/DimensionProvider";
import useTierService from "@src/ducks/hooks/useTierService";
import { ImageButton06 } from "components/button";
import { Audio } from "expo-av";
import numeral from "numeral";
import React from "react";
import { Image, Text, View } from "react-native";
import audio from "theme/audio";
import colors from "theme/colors";
import useStyles from "./styles.css";

const SuccessfulPurchase = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const silver = route?.params?.silver;
  const gold = route?.params?.gold ?? 0;
  const navigation = useNavigation<any>();
  const { onCheckTierRequest } = useTierService();
  const soundRef = React.useRef<any>(new Audio.Sound());
  const { baseWidth } = React.useContext(DimensionContext);

  const onPress = () => {
    navigation.dispatch(StackActions.pop(2));
  };

  const syncSounds = React.useCallback(async() => {
    try {
      await soundRef.current.loadAsync(audio["ref_wistle"]);
      soundRef.current.playAsync();
    } catch (e) { console.log("eeee", e); }
  }, []);

  React.useEffect(() => {
    syncSounds();
    
    return soundRef.current ? () => {
      soundRef.current.unloadAsync();
      onCheckTierRequest();
    } : undefined;
  }, []);


  return (
    <View style={styles.container}>
      <View
        style={[
          styles.center_view,
          { width: baseWidth * 0.57, height: baseWidth * 0.19 },
        ]}
      >
        <Image
          style={styles.i_background}
          source={{uri: images["bg-notice"]}}
          resizeMode="stretch"
        />
        <Image
          style={styles.i_referee}
          source={{uri: images["referee"]}}
          resizeMode="stretch"
        />
        <View style={styles.v_notice}>
          <Image
            style={styles.i_background}
            source={{uri: images["notice"]}}
            resizeMode="stretch"
          />
          <Image
            style={styles.i_exclamation}
            source={{uri: images["notice-check"]}}
            resizeMode="stretch"
          />
        </View>
        <View style={styles.v_body}>
          <Image
            style={{width: "55%", height: "17%"}}
            source={{uri: images["successful-purchase"]}}
            resizeMode="stretch"
          />
          <Text style={[styles.t_notes, {marginTop: "2%", fontSize: baseWidth * 0.013, lineHeight: baseWidth * 0.018}]}>
            Закупи <Text style={{color: colors.skyblue1}}>{numeral(silver).format("0,000")}</Text> Сребърни монети 
            {gold > 0 ? <Text style={{color: colors.yellow3}}> + {gold} Бонус Златна монета</Text> : null}.
            {"\n"}Забавлявай се!
          </Text>
          <View style={styles.v_buttons}>
            <ImageButton06
              onPress={onPress}
              label="Затвори"
              style={styles.btn_style}
              source={images["btn-big"]}
              labelStyle={{fontSize: baseWidth * 0.011  }}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default SuccessfulPurchase;
