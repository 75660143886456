import images from "@assets/images";
import { useDimensionContext } from "@src/DimensionProvider";
import React from "react";
import { Image, View } from "react-native";
import Ball from "./ball";
import Bonus from "./bonus";
import Collect from "./collect";
import HotDeals from "./hotdeals";
import LeaderBoard from "./leaderboard";
import Mission from "./mission";
import ResponsiveProvider from "./provider";
import ShopButton from "./shop";
import useStyles from "./styles.css";

const BottomNav = () => {
  const styles = useStyles();
  const { hudHeight } = useDimensionContext();

  return (
    <ResponsiveProvider>
      <>
        <View pointerEvents="box-none" style={styles.wrapcenter}>
          <Image
            source={{uri: images.hud_bot}}
            style={[styles.style_navbottom]}
            resizeMode="stretch"
          />
          <View style={[styles.leftStyle, { gap: hudHeight * 0.03, justifyContent: "flex-end" }]}>
            <Bonus />
            <Collect />
            <LeaderBoard />
          </View>
          <View style={styles.middleStyle}>
            <ShopButton />
          </View>
          <View style={[styles.rightStyle]}>
            <View style={[styles.rightStyleCenter, { gap: hudHeight * 0.12 }]}>
              <HotDeals />
              <Ball />
              <Mission />
            </View>
          </View>
        </View>
      </>
    </ResponsiveProvider>
  );
};

export default React.memo(BottomNav);
