import images from "@assets/images";
import { PALMSBET_WEBSITE } from "@env";
import { ImageButton06 } from "components/button";
import React from "react";
import { signOut } from "utils/api";
import { useFetchPost } from "utils/api/hooks";

const Logout = ({ baseWidth, baseHeight }: any) => {
  const { loading, runCallbackRequest } = useFetchPost();

  const gotoHome = () => {
    try {
      document.body.classList.add("loading");
      // Check if we have access to window.top
      if (window.top !== window.self) {
        // Change the top-level window's URL
        window.top.location.href = PALMSBET_WEBSITE;
      }else {
        // Change the top-level window's URL
        window.location.href = PALMSBET_WEBSITE;
      }
    } catch (error) {
      console.error("Cross-origin access blocked:", error);
      // Handle the error (use postMessage, show an error message, etc.)
      window.parent.postMessage({ action: "HOME" }, "*");
    }
  };

  const handleLogout = () => {
    runCallbackRequest(
      () => signOut(),
      () => {
        gotoHome();
      },
      () => {
        gotoHome();
      }
    );
  };

  return (
    <ImageButton06 
      source={{uri: images["btn-big"]}}
      onPress={handleLogout} 
      label="Излез"
      isLoading={loading}
      style={{width: baseWidth * 0.531, height: baseHeight * 0.13}} />
  );
};

export default React.memo(Logout);
