import React, { useEffect, useState } from "react";
import { Text } from "react-native";
import { calculateTimeLeft } from "utils/filter-helper";
import useStyles from "./styles.css";
import { useFetchGet } from "utils/api/hooks/useFetch";

type CountdownTimer = {
  baseWidth: number;
}

const CountdownTimer = ({ baseWidth }: CountdownTimer) => {
  const styles = useStyles();
  const { data } = useFetchGet("/api/v1/leaderboard/current-week");
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(data?.endDate));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(data?.endDate));
    }, 1000);

    return () => clearInterval(timer);
  });

  const formatTime = (time: any) => {
    return time < 10 ? `0${time}` : time;
  };

  return (
    <Text style={[styles.t_duration, { fontSize: baseWidth * 0.011}]}>
      Приключва след: {`${timeLeft.days}д ${formatTime(timeLeft.hours)}ч ${formatTime(timeLeft.minutes)}м`}
    </Text>
  );
};

export default CountdownTimer;
