import { colors } from "@src/theme";
import { CreateResponsiveStyle } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    v_player_container: { 
      width: "78%",
      height: "17%",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center", 
      alignSelf: "center"
    },
    v_player_center: { 
      width: "75%", 
      height: "100%",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between", zIndex: 2 },
    v_bg_player: {
      width: "100%", 
      height: "100%",
      position: "absolute",
    },
    v_current_player: {
      width: "87%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },    
    t_player_rank: {
      fontSize: 15,
      color: colors.white,
      fontFamily: "Roboto-Bold",
    },
    t_player_name: {
      left: "2%",
      fontSize: 16,
      color: colors.white,
      fontFamily: "Roboto-Bold",
    },


    // RANKER
    v_your_rank: {
      width: "18%", height: "120%", top: "2%", 
      right: "0%", 
      position: "absolute",
    },
    i_your_rank: {
      width: "100%",
      height: "100%",
    },
    t_rank_level: {
      fontFamily: "Roboto-Bold",
      color: colors.green5,
      alignSelf: "center",
      position: "absolute",
      bottom: "9.6%",
    },

    t_duration: {
      marginTop: "4%",
      fontFamily: "Roboto-Bold",
      color: colors.white,
      alignSelf: "center",
    },
    btn_edit_profile: { width: "50%", height: "20%", marginTop: "4%", marginLeft: "4%" },
  },
);

export default useStyles;
