import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { colors } from "@src/theme";
import { moderateScale, verticalScale } from "react-native-size-matters";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: "45%",
      height: "80%",
      alignItems: "center",
      justifyContent: "center",
    },
    silverBar: {
      width: "100%",
      height: "50%",
    },
    silverCoin: {
      zIndex: 2,
      width: "35%",
      height: "75%",
      bottom: "2%",
    },
    v_score: {
      alignSelf: "center", position: "absolute", marginBottom: verticalScale(7),
      alignItems: "center", justifyContent: "center",
    },
    silverText: {
      alignSelf: "center",
      color: colors.white,
      fontFamily: "RoboRoboto-Mediumto_Medium",
      fontSize: moderateScale(14),
    },
  },
  {
    [DEVICE_SIZES.LG]: {
      container: { width: "50%" },
      silverText: { fontSize: moderateScale(10) },
    },
    [DEVICE_SIZES.MD]: {
      container: { width: "50%" },
      silverBar: { height: "52%" },
      silverText: { fontSize: 12 },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      container: { width: "50%" },
      silverBar: { height: "52%" },
      silverText: { fontSize: 11 },
    },
  }
);

export default useStyles;
