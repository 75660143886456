import images from "@assets/images";
import { useNavigation } from "@react-navigation/native";
import { ImageButton } from "components/button";
import React from "react";
import { ResponsiveContext } from "../provider";
import useStyles from "./styles.css";

const LeaderBoard = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { baseHeight } = React.useContext(ResponsiveContext);
  const onPress = () =>  navigation.navigate("LeaderBoard");

  return (
    <ImageButton
      scaleSize={0.97}
      onPress={onPress}
      activeOpacity={1}
      source={{uri: images["lobby-btn-leaderboard"]}}
      style={[styles.silverCoin, { width: baseHeight * 1.017, height: baseHeight * 1.017 }]}
    />
  );
};

export default LeaderBoard;
