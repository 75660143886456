import { colors } from "@src/theme";
import { CreateResponsiveStyle } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    v_timer: {
      alignItems: "center",
      justifyContent: "center",
    },
    i_timer_mask: {width: "100%", height: "100%", position: "absolute"},
    goldBar: {
      width: "85%",
      height: "44%",
    },
    btnCollect: {
      zIndex: 3,
      left: "2%",
      width: "40%",
      height: "86%",
      position: "absolute",
    },
    indicator: {
      zIndex: 2,
      position: "absolute",
    },
    imageCollect: {
      width: "100%",
      height: "100%",
    },
    goldText: {
      lineHeight: 24,
      color: colors.white,
      fontFamily: "Roboto-Bold",
    },
  }
);

export default useStyles;
