import { useCallback } from "react";
import { useAppDispatch } from "../ducksHook";
import { tierActions } from "../slices/tier.slice";

type ServiceOperators = {
  onCheckTierRequest: () => void;
  onClaimTierUpgradeReward: (params: any) => void;
  onSetUnlockedTierRewards: (params: any) => void;
};

const useTierService = (): Readonly<ServiceOperators> => {
  const dispatch = useAppDispatch();

  return {
    onCheckTierRequest: useCallback(() => dispatch(tierActions.checkTierRequest()),
      [dispatch]
    ),
    onClaimTierUpgradeReward: useCallback(
      (params: any) => dispatch(tierActions.claimTierUpgradeBonusRequest(params)),
      [dispatch]
    ),
    onSetUnlockedTierRewards: useCallback(
      (params: any) => dispatch(tierActions.setUnlockedTierLevels(params)),
      [dispatch]
    )
  };
};

export default useTierService;