import images from "@assets/images";
import { useNavigation } from "@react-navigation/native";
import { ImageButton06 } from "components/button";
import React from "react";
import { View } from "react-native";
import { runOnJS, SharedValue, useAnimatedReaction } from "react-native-reanimated";
import useStyles from "./styles.css";

type Props = {
  onCancel: () => void;
  animatedValue: SharedValue<number>;
}

const ItemShop = (props: Props) => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const [enabled, setEnabled] = React.useState(false);

  const handlePress = () => {
    if(enabled){
      navigation.navigate("BuyShop");
    }
  };

  useAnimatedReaction(
    () => props.animatedValue.value,
    (value, previousValue) => {
      if (value !== previousValue) {
        runOnJS(setEnabled)(value === 1);
      }
    }
  );

  return (
    <View style={styles.v_container_shop}>
      <ImageButton06 
        scaleSize={1}
        onPress={handlePress} 
        disabled={!enabled}
        style={styles.v_item_shop}
        source={{uri: images["palm-goto-shop"]}}
      />
    </View>
  );
};

export default ItemShop;
