import { CreateResponsiveStyle } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center"
    },
    imageStyle: {
      width: "100%",
      height: "100%",
      position: "absolute",
      borderRadius: 1000,
    },
    image_inactive: { transform: [{ scale: 0.9 }], opacity: 0.5},
    v_timer: {
      position: "absolute", 
      backgroundColor: "#F5E5F9",
      alignItems: "center",
      justifyContent: "center"
    },
    t_timer: {
      color: "black",
      fontFamily: "Roboto-Bold",
    },
  },
);

export default useStyles;
