import { useNavigation } from "@react-navigation/native";
import { DimensionContext } from "@src/DimensionProvider";
import Loading from "components/loading/Loading";
import React from "react";
import Lottie from "react-lottie-player";
import { Pressable, StyleSheet } from "react-native";
import colors from "theme/colors";

const HotDealsButton = () => {
  const navigation = useNavigation<any>();
  const [animationData, setAnimationData] = React.useState();
  const { hudHeight } = React.useContext(DimensionContext);

  React.useEffect(() => {
    import("@assets/json/hot-deals-button").then(async(res: any) => {
      setAnimationData(res.default);
    });
  }, []);

  if (!animationData) return <Loading customStyle={{backgroundColor: colors.translucent3 }} isLoading />;

  return (
    <Pressable 
      onPress={() => navigation.navigate("HotDealsThumbnails")}
      style={[styles.container, { width: hudHeight * 1.2, height: hudHeight * 1.017 }]}>
      <Lottie
        loop
        play
        animationData={animationData}
        rendererSettings={{ preserveAspectRatio: "xMinYMid slice" }}
        style={{width: hudHeight * 1.5, height: hudHeight * 1.2, left: "-22%", position: "absolute" }}
      />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: { zIndex: 1, 
    width: "26%",
    height: "100%",
    bottom: "20%",
    left: 0 
  }
});

export default HotDealsButton;
