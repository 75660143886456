import { SagaIterator } from "@redux-saga/core";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { claimTierUpgradeBonus, fetchAvatarTier, fetchTierUpgradeBonuses } from "utils/api";
import { tierActions } from "../slices/tier.slice";

// Types
import _ from "lodash";
import { authActions, selectedAuthToken } from "../slices/auth.slice";
import { lobbyActions } from "../slices/lobby.slice";
import { selectedUserUserID } from "../slices/user.slice";
import * as Types from "../types";

function* handleClaimTierUpgradeBonusRequest(action: {
    type: typeof tierActions.claimTierUpgradeBonusRequest,
    payload: Types.Transaction;
  }): SagaIterator {
  try {
    // claim reward
    const accessToken = yield select(selectedAuthToken);
    const result = yield call(claimTierUpgradeBonus, action.payload, accessToken);
    yield put(tierActions.claimTierUpgradeBonusSuccess(result));
  } catch (error) {
    yield put(tierActions.claimTierUpgradeBonusFailed(error));
  }
}

function* handleTierUpgradeRequest(): SagaIterator {
  try {
    const userId = yield select(selectedUserUserID);
    const accessToken = yield select(selectedAuthToken);
    const tierUpgradeBonuses = yield call(fetchTierUpgradeBonuses, { userId }, accessToken);
    const avatarTier = yield call(fetchAvatarTier, { userId }, accessToken);
    yield put(lobbyActions.avatarTier(avatarTier));
    yield put(tierActions.tierUpgradeBonuses(tierUpgradeBonuses.content));
  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.logout());
      yield put(authActions.setErrorMessage("Session Expired!"));
    }
  }
}

function* handlePopupScreenToDisplay(action: {
  type: typeof tierActions.tierUpgradeBonuses,
  payload: any;
}): SagaIterator {
  try {
    const popScreen = [];
    const unclaimedBonuses = action.payload;

    if (!_.isEmpty(unclaimedBonuses)) {
      const bonus = unclaimedBonuses[0];
      if (bonus) {
        popScreen.push({
          routeName: "TierLevelUp",
          params: {
            bonus
          }
        });
        yield put(lobbyActions.popupScreenToDisplay(popScreen));
      }
    }
  } catch (error: any) {
    // empty
  }
}

function* tierWatcherSaga(): SagaIterator {
  yield takeEvery(tierActions.checkTierRequest.type, handleTierUpgradeRequest);
  yield takeEvery(tierActions.claimTierUpgradeBonusRequest.type, handleClaimTierUpgradeBonusRequest);
  yield takeLatest(tierActions.tierUpgradeBonuses.type, handlePopupScreenToDisplay);
}

export default tierWatcherSaga;