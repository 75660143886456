import { useNavigation } from "@react-navigation/native";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedGModeVisit } from "@src/ducks/slices/gmode.slice";
import { selectedMissionHasKeys, selectedPopupScreenToDisplay } from "@src/ducks/slices/lobby.slice";
import { selectedSilverCoins } from "@src/ducks/slices/user.slice";
import _ from "lodash";
import React, { createContext } from "react";

interface ContextValue {
}

export const HomeContext = createContext<ContextValue>({
} as ContextValue);

interface HomeProviderProps {
  children: React.ReactElement;
}

const HomeProvider = ({ children }: HomeProviderProps) => {
  const navigation = useNavigation<any>();
  const hasPlayGame = useAppSelector(selectedGModeVisit);
  const missionKeys = useAppSelector(selectedMissionHasKeys);
  const isLowSilverBalance = useAppSelector(selectedSilverCoins);
  const popupscreen = useAppSelector(selectedPopupScreenToDisplay);

  React.useEffect(() => {
    if (!_.isEmpty(popupscreen)) {
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        if(typeof popupscreen[0] === "string"){
          navigation.navigate(popupscreen[0]);
        }else if(typeof popupscreen[0] === "object"){
          navigation.navigate(popupscreen[0].routeName, popupscreen[0].params);
        }
      }, 1000);
    }
  },[popupscreen]);

  React.useEffect(() => {
    if (hasPlayGame && isLowSilverBalance) {
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        navigation.navigate("PremiumPackage");
      }, 1000);
    }
  }, [isLowSilverBalance, hasPlayGame]);

  React.useEffect(() => {
    if (missionKeys) {
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        navigation.navigate("MissionKey");
      }, 1000);
    }
  }, [missionKeys]);

  return (
    <HomeContext.Provider value={{}}>
      {children}
    </HomeContext.Provider>
  );
};

export default HomeProvider;
