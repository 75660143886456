import images from "@assets/images";
import { useNavigation } from "@react-navigation/native";
import { DimensionContext } from "@src/DimensionProvider";
import { ImageButton } from "components/button";
import React from "react";

const Ball = () => {
  const navigation = useNavigation<any>();
  const { hudHeight } = React.useContext(DimensionContext);

  return (
    <ImageButton
      source={{uri: images["lobby-btn-golden-ball"]}}
      onPress={() => navigation.navigate("GoldenBall")}
      style={[{ width: hudHeight, height: hudHeight * 1.02, bottom: "1%", marginRight: "2%" }]}
    />
  );
};

export default React.memo(Ball);
