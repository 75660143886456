// DUCKS pattern
import { createAction, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@src/ducks/store";
import { ErrorValue, LoginInput, SessionValue } from "../types";

interface AuthState {
  loading: boolean;
  isLoggedIn: boolean;
  session: SessionValue;
  error: ErrorValue;
  errorMessage: string;
  termsAndCondition: boolean;
}

export const initialState: AuthState = {
  loading: false,
  isLoggedIn: false,
  errorMessage: "",
  termsAndCondition: false,
} as AuthState;

// Slice
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginRequest: (state) => {
      state.loading = true;
    },
    loginGoogleRequest: (state) => {
      state.loading = true;
    },
    loginFBRequest: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, action) => {
      state.session = action.payload;
      state.error = {} as ErrorValue;
      state.loading = false;
      state.isLoggedIn = true;
    },
    loginFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetLoading: (state) => {
      state.loading = false;
    },
    logout: (state) => {
      return {
        ...initialState, 
        termsAndCondition: state.termsAndCondition, 
      };
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    termsAndCondition: (state, action) => {
      state.termsAndCondition = action.payload;
    },
    refreshToken: (state, action) => {
      state.session.refreshToken = action.payload.refreshToken;
      state.session.accessToken = action.payload.accessToken;
    },
  },
});

// Actions
export const authActions = {
  loginRequest: createAction(
    `${authSlice.name}/loginRequest`,
    (params: LoginInput) => ({
      payload: params,
    })
  ),
  loginGoogleRequest: createAction(
    `${authSlice.name}/loginGoogleRequest`,
    (params: string) => ({
      payload: params,
    })
  ),
  loginFBRequest: createAction(
    `${authSlice.name}/loginFBRequest`,
    (params: string) => ({
      payload: params,
    })
  ),
  loginSuccess: authSlice.actions.loginSuccess,
  loginFailure: authSlice.actions.loginFailure,
  logout: authSlice.actions.logout,
  setErrorMessage: authSlice.actions.setErrorMessage,
  termsAndCondition: authSlice.actions.termsAndCondition,
  resetLoading: authSlice.actions.resetLoading,
  refreshToken: authSlice.actions.refreshToken,
};

// Selectors
export const selectAuthLoggingIn = (state: RootState) => state.auth.loading;
export const selectAuthLoggedIn = (state: RootState) => state.auth.isLoggedIn;
export const selectAuthLogInFailed = (state: RootState) => state.auth.error;
export const selectAuthSession = (state: RootState) => state.auth.session;
export const selectErrorMessage = (state: RootState) => state.auth.errorMessage;
export const selectedTermsAndCondition = (state: RootState) => state.auth.termsAndCondition;

export const selectedAuthSession = createSelector(
  (state: RootState) => state.auth.session,
  (state: RootState) => state.user.userDetails,
  (session, user) => ({
    userId: user.id,
    token: session?.accessToken,
  })
);

export const selectedAuthToken = createSelector(
  (state: RootState) => state.auth.session,
  (session) => session?.accessToken,
);

export const selectedAuthRefreshToken = createSelector(
  (state: RootState) => state.auth.session,
  (session) => session?.refreshToken,
);

// Reducer
export default authSlice.reducer;
