import images from "@assets/images";
import { useDimensionContext } from "@src/DimensionProvider";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useLobbyService } from "@src/ducks/hooks";
import { selectedGoldAllowStatus } from "@src/ducks/slices/games.slice";
import { ImageButton } from "components/button";
import React from "react";

const GameSwitch = () => {
  const { hudHeight } = useDimensionContext();
  const { onSwitchCurrency } = useLobbyService();
  const gold = useAppSelector(selectedGoldAllowStatus);

  return (
    <ImageButton
      source={gold ? images["lobby-btn-switch-gold"] : images["lobby-btn-switch-silver"]}
      onPress={() => onSwitchCurrency(!gold)}
      style={[{ top: "3%", width: hudHeight * 1, height: hudHeight * 0.63 }]}
    />
  );
};

export default React.memo(GameSwitch);
