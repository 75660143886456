import images from "@assets/images";
import { useNavigation } from "@react-navigation/native";
import { useDimensionContext } from "@src/DimensionProvider";
import { ImageButton06 } from "components/button";
import numeral from "numeral";
import React from "react";
import { Image, Text, View } from "react-native";
import useStyles from "./styles.css";
import CountdownTimer from "./countdown";

const RankDetails = ({ user }: any) => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { baseWidth } = useDimensionContext();

  const Trophy = React.useMemo(() => {
    switch(user.rank){
    case 1:
      return images.btn_rank;
    case 2:
      return images.trophy2;
    case 3:
      return images.trophy3;
    }
  },[user.rank]);


  return (
    <>
      <View style={styles.v_player_container}>
        <Image source={{uri: images["leaderboard-rank-bar"]}} 
          style={{width: "100%", height: "100%", position: "absolute" }} 
          resizeMode="stretch" />
        <View style={styles.v_player_center}>
          <Text style={[styles.t_player_rank, { fontSize: baseWidth * 0.0176}]}>
            {numeral(user.totalScore).format("0,000")}
          </Text>
        </View>
        <View style={styles.v_your_rank}>
          <Image
            style={styles.i_your_rank}
            source={{uri: images.l_your_rank}}
            resizeMode="stretch"
          />
          <Text style={[styles.t_rank_level, { fontSize: baseWidth * 0.0125}]}>{user.rank}</Text>
        </View>
        <Image source={{uri: Trophy}} 
          style={{width: "11%", height: "90%"}} 
          resizeMode="stretch" />
      </View>
      <CountdownTimer baseWidth={baseWidth} />
      <ImageButton06
        onPress={() => navigation.push("LeaderBoard")}
        style={[
          styles.btn_edit_profile,
          { width: baseWidth * 0.18, height: baseWidth * 0.053, marginRight: baseWidth * 0.01 },
        ]}
        label="Виж Класацията"
        source={images["btn-big"]}
        labelStyle={{fontSize: baseWidth * 0.013}}
      />
    </>
  );
};

export default React.memo(RankDetails);