import { CreateResponsiveStyle } from "rn-responsive-styles";
import { StyleSheet } from "react-native";
import { colors } from "@src/theme";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      height: "90%",
      position: "absolute",
      alignItems: "center",
    },
    bodyStyle: {
      height: "98%",
      width: "94%",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    i_bg: {
      width: "100%",
      height: "100%",
      ...StyleSheet.absoluteFillObject,
    },
    v_bg: {
      // top: "13%",
      width: "96%",
      height: "100%",
      flexDirection: "row",
    },

    // AVATAR STYLES
    v_container: {
      width: "33%",
      height: "97.5%",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    v_topdivider: { height: "1%", width: 100 },
    v_centerframe: {
      width: "89%",
      height: "58%",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    i_avatar_frame: {
      zIndex: 1,
      width: "100%",
      height: "60%",
      position: "absolute",
    },
    i_avatar_wb: {
      zIndex: 2,
      width: "100%",
      height: "100%",
    },
    btn_change: { marginBottom: "5%", width: "60%", height: "13%", zIndex: 3 },

    // PROFILE STYLE
    p_container: {
      zIndex: 10,
      width: "31.96%",
      height: "90%",
      alignItems: "center",
    },
    v_center_profile: {
      top: "12%",
      width: "100%",
      height: "85%",
      alignItems: "center",
    },
    i_profile_frame: {
      zIndex: 1,
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    v_profile_divider: {
      zIndex: 2,
      width: 100,
      height: "25%",
    },
    v_details: {
      zIndex: 4,
      width: "88%",
      height: "65%",
      alignItems: "center",
      justifyContent: "space-evenly",
    },
    v_player: {
      width: "72%",
      height: "15%",
      alignItems: "center",
      justifyContent: "center",
    },
    i_player_bg: {
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    btn_edit: {
      top: -6,
      right: -6,
      zIndex: 4,
      width: 24,
      height: 24,
      position: "absolute",
    },
    t_player_name: {
      zIndex: 2,
      fontSize: 12,
      color: colors.white,
      fontFamily: "Roboto",
    },
    t_userid: {
      zIndex: 2,
      fontSize: 12,
      marginTop: "5%",
      color: colors.white,
      fontFamily: "Roboto",
    },
    i_yourrank: {
      zIndex: 3,
      left: "2%",
      // bottom: "-4%",
      flexDirection: "row",
      alignItems: "center",
      // marginVertical: "2%",
      marginBottom: "6%",
    },
    v_ranking: {
      width: "72%",
      height: "15%",
      alignItems: "center",
      justifyContent: "center",
    },
    t_user_id: {
      marginTop: "2%",
      fontSize: 20,
      color: colors.white,
      fontFamily: "Roboto-Bold",
    },
    btn_edit_profile: { width: "50%", height: "20%", marginTop: "4%", marginLeft: "4%" },
    btn_back: {
      zIndex: 5,
      bottom: "6%",
      width: "20%",
      height: "10%",
      marginRight: 15,
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
    },

    // TIER STYLES
    t_topdivider: { height: "8%", width: 100 },
    i_yourtier: {
      top: "13%",
      zIndex: 3,
      width: "70%",
      height: "25%",
      position: "absolute",
    },
    v_tiercenter: {
      width: "100%",
      height: "50%",
      alignItems: "center",
      justifyContent: "center",
    },
    i_tierlevel: {
      zIndex: 1,
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    btn_tier: { width: "60%", height: "13%", zIndex: 3, marginBottom: "6%" },
  },
);

export default useStyles;
