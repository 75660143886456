import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedUserCoins } from "@src/ducks/slices/user.slice";
import numeral from "numeral";
import React from "react";
import { Text } from "react-native";
import { CoinContext } from "../../main/CoinProvider";
import useStyles from "./styles.css";

const GoldCoins = ({ baseHeight }: any) => {
  const styles = useStyles();
  const countRef = React.useRef<any>();
  const [deducted, setDeducted] = React.useState(0);
  const balance  = useAppSelector(selectedUserCoins);
  const [addamount, setAddAmount] = React.useState(0);
  const [currentGold, setCurrentGold] = React.useState(0);
  const { currency, onAdditionalCurrency } = React.useContext(CoinContext);

  const onIncrease = (amount: number) => {
    setAddAmount(amount);
    setDeducted(amount / 360);
  };

  React.useEffect(() => {
    setCurrentGold(balance.GOLD + balance["GOLD BONUS"]);
  }, [balance.GOLD, balance["GOLD BONUS"]]);

  React.useEffect(() => {
    if (addamount <= 0) {
      countRef.current && clearInterval(countRef.current);
      setCurrentGold((lastNumber) => {
        return lastNumber + addamount;
      });
      onAdditionalCurrency({ silver: 0, bg: 0, gwz: 0});
      setAddAmount(0);
      return;
    }

    countRef.current = setInterval(() => {
      setAddAmount((lastNumber) => {
        return lastNumber - deducted;
      });
      setCurrentGold((lastNumber) => {
        return lastNumber + deducted;
      });
    }, 1);

    return () => clearInterval(countRef.current);
  }, [addamount, deducted]);

  React.useEffect(() => {
    if (currency.bg > 0) {
      onIncrease(currency.bg);
    }else if (currency.gwz > 0 || currency.gwz <= -1) {
      onIncrease(currency.gwz);
    }
  }, [currency.bg, currency.gwz]);

  return (
    <Text style={[styles.goldText, { fontSize: baseHeight * 0.17}]}
    >{numeral(currentGold).format("0,000.00")}</Text>
  );
};

export default GoldCoins;
