import images from "@assets/images";
import { DimensionContext } from "@src/DimensionProvider";
import React from "react";
import { Image, View } from "react-native";
import Avatar from "./avatar";
import Email from "./email";
import GameSwitch from "./game-switch";
import GoldBar from "./goldbar";
import PalmPoints from "./palm-points";
import Home from "./home";
import Settings from "./settings";
import SilverBar from "./silverbar";
import useStyles from "./styles.css";

const TopNav = () => {
  const styles = useStyles();
  const { hudHeight } = React.useContext(DimensionContext);

  return (
    <View style={[styles.vw_topnav, { height: hudHeight}]}>
      <Image
        source={{uri: images.hud_top}}
        style={styles.style_navtop}
        resizeMode="stretch"
      />
      <View style={styles.wrapcenter}>
        <View style={styles.leftStyle}>
          <View style={[styles.leftStyleCenter, { gap: hudHeight * 0.01, justifyContent: "flex-end" }]}>
            <Home />
            <Email />
            <GameSwitch />
            <SilverBar />
          </View>
        </View>
        <View style={styles.middleStyle}>
          <Avatar />
        </View>
        <View style={styles.rightStyle}>
          <View style={[styles.leftStyleCenter, { gap: hudHeight * 0.03 }]}>
            <GoldBar />
            <PalmPoints />
            <Settings />
          </View>
        </View>
      </View>
    </View>
  );
};

export default React.memo(TopNav);
