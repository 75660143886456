import images from "@assets/images";
import { PALMSBET_WEBSITE } from "@env";
import { DimensionContext } from "@src/DimensionProvider";
import { ImageButton06 } from "components/button";
import React from "react";
import { Image, Text, View } from "react-native";
import useStyles from "./styles.css";

const LoginToPalms = () => {
  const styles = useStyles();
  const { baseWidth, maxHeight } = React.useContext(DimensionContext);

  const onPress = () => {
    try {
      document.body.classList.add("loading");
      // Check if we have access to window.top
      if (window.top !== window.self) {
        // Change the top-level window's URL
        window.top.location.href = PALMSBET_WEBSITE;
      }else {
        // Change the top-level window's URL
        window.location.href = PALMSBET_WEBSITE;
      }
    } catch (error) {
      console.error("Cross-origin access blocked:", error);
      // Handle the error (use postMessage, show an error message, etc.)
      window.parent.postMessage({ action: "HOME" }, "*");
    }
  };

  return (
    <View style={styles.palms_container}>
      <View style={[styles.bodyStyle, { height: maxHeight * 0.4031}]}>
        <Image
          source={{uri: images.bg_popup}}
          style={styles.imageBorderStyle}
          resizeMode="stretch"
        />
        <Image
          source={{uri: images["gamezone-logo-horizontal"]}}
          style={styles.palms_logo}
          resizeMode="stretch"
        />
        <View style={styles.palms_row}>
          <Image
            resizeMode="stretch"
            source={{uri: images["exclamation"]}}
            style={styles.palms_notice_icon}
          />
          <Text style={[styles.palms_notes, { fontSize: baseWidth * 0.017 }]}>
          ВЛЕЗ В PALMS, ЗА ДА ИМАШ ДОСТЪП ДО GAME ZONE 
          </Text>
        </View>
        <ImageButton06
          label="ПРОДЪЛЖИ"
          onPress={onPress}
          style={[styles.palms_btn_continue]}
          source={images["btn-big"]}
          labelStyle={{fontSize: baseWidth * 0.011}}
        />
      </View>
    </View>
  );
};

export default LoginToPalms;
