import { CommonActions, useNavigation, useRoute } from "@react-navigation/native";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useAuthService, useErrorService } from "@src/ducks/hooks";
import { selectAuthLoggedIn, selectAuthSession, selectedAuthRefreshToken } from "@src/ducks/slices/auth.slice";
import SplashWOProgress from "components/splash/splash-wo-progress";
import React from "react";
import { palmsbetVerifyAccount, refreshToken } from "utils/api";
import { useFetchPost } from "utils/api/hooks";

const PalmsSigningVerification = () => {
  const route = useRoute<any>();
  const navigation = useNavigation<any>();
  const { runCallbackRequest } = useFetchPost();
  const { onSetErrorMessage } = useErrorService();
  const session = useAppSelector(selectAuthSession);
  const isLoggedIn = useAppSelector(selectAuthLoggedIn);
  const RToken = useAppSelector(selectedAuthRefreshToken);
  const { onLogout, onLoginSuccess, onRefreshTokenUpdate } = useAuthService();

  const handleSigningIn = () => {
    const params = {
      "account": route.params?.account,
      "accountCurrency": route.params?.account_currency,
      "cs": route.params?.cs,
      // "ipAddress": route.params?.account,
      "isReal": route.params?.is_real,
      "language": route.params?.language,
      "launchedAtUtc": route.params?.launched_at_utc,
      "platform": route.params?.account,
      "session": route.params?.session,
      "version": route.params?.version
    };

    runCallbackRequest(
      () => palmsbetVerifyAccount(params),
      (response) => {
        onLoginSuccess({...response, pb_account: route.params});
        if(isLoggedIn){
          navigation.dispatch(
            CommonActions.reset({
              index: 1,
              routes: [
                { name: "Home" },
              ],
            })
          );
        }
      },
      (error) => {
        console.log("errorerror", error);
        if(error.message){
          onSetErrorMessage(error?.message);
          navigation.navigate("SignInOption");
        }
      }
    );
  };

  const handleRefreshToken = async() => {
    try {
      if(route.params.account === session?.pb_account?.account){
        const resultRefreshToken = await refreshToken(RToken);
        onRefreshTokenUpdate(resultRefreshToken);
        navigation.dispatch(
          CommonActions.reset({
            index: 1,
            routes: [
              { name: "Home" },
            ],
          })
        );
      }else{
        onLogout();
      }
    } catch (error) {
      handleSigningIn();
    }
  };

  React.useEffect(() => {
    if(isLoggedIn){
      handleRefreshToken();
    }else{
      handleSigningIn();
    }
  }, [route.params, isLoggedIn]);

  return <SplashWOProgress />;
};

export default PalmsSigningVerification;
